@import "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap";
.spinner-container {
  z-index: 1000;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top-color: #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: 2s linear infinite spin;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.login-error {
  color: #fff;
  background-color: #a92d42;
  border: 1px solid #ff7686;
  border-radius: 6px;
  max-width: 50%;
  margin: 20px 0;
  padding: 20px;
  transition: opacity .3s ease-in-out;

  @media (width <= 768px) {
    & {
      max-width: 100%;
    }
  }
}

nav {
  background-color: var(--bg-color);
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  display: flex;
  position: sticky;
  top: 0;

  & .nav {
    flex-grow: 4;
    justify-content: flex-start;
    align-items: center;
    display: flex;

    & * {
      margin-right: 1rem;
    }
  }

  & .logout {
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
    display: flex;
  }
}

.hamburger-menu {
  fill: #fff;
  background-color: #0000;
  border: none;
}

.dropdown-menu {
  background-color: var(--bg-color);
  z-index: 1000;
  border: 1px solid #418feb;
  border-radius: 4px;
  min-width: auto;
  margin: 0;
  padding: 10px;
  list-style: none;
  position: absolute;
  top: 100%;
  right: 0;
  overflow: hidden;
}

.dropdown-container {
  display: inline-block;
  position: relative;
}

.dropdown-menu li {
  white-space: nowrap;
  padding: 12px 20px;
}

.carriage-icon {
  width: 50px;
  height: 50px;
  margin: 10px;
  transition: transform .3s, filter .3s;
  display: inline-block;

  &:hover {
    filter: brightness(1.2);
    transform: scale(1.1);
  }
}

.breadcrumbs-container {
  align-items: center;
  padding: 1rem 2rem;
  display: flex;
}

.breadcrumbs {
  gap: 8px;
  display: flex;
}

.breadcrumbs a {
  color: var(--breadcrumb-color);
  font-weight: bold;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.breadcrumbs span {
  color: gray;
}

:root {
  --bg-color: #121212;
  --text-color: #fff;
  --breadcrumb-color: #499bff;
  --pagination-bgcolor: #489bff33;
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
  font-family: Open Sans, sans-serif;
  transition: background-color .3s, color .3s;
}

body.light {
  --bg-color: #fff;
  --text-color: #000;
}

.layout {
  padding: 1rem 2rem;
}

h1, h2, h3 {
  margin: 0;
}

button {
  cursor: pointer;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: .9em;
}

a {
  color: #499bff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

svg {
  stroke: var(--text-color);
}

.btn-primary {
  color: #fff;
  background-color: #499bff;
  border: none;

  &:hover {
    background-color: #418feb;
  }
}

.btn-secondary {
  color: #499bff;
  background-color: #0000;
  border: 1px solid #418feb;

  &:hover {
    color: #fff;
    background-color: #418feb;
  }
}

.btn-dropdown {
  color: #499bff;
  background-color: #0000;
  border: none;

  &:hover {
    text-decoration: underline;
  }
}

.camera-display {
  display: flex;

  @media (width <= 768px) {
    & {
      flex-flow: wrap;
    }
  }
}

.camera {
  flex-direction: column;
  flex-basis: 50%;
  max-width: 50%;
  margin-right: 20px;
  padding-top: 30px;
  display: flex;

  & .camera-info {
    align-items: baseline;
    padding-bottom: 20px;
    display: flex;

    & * {
      margin-right: 1rem;
    }
  }

  & img {
    border-radius: 5px;
    width: 100%;
    height: auto;
  }

  @media (width <= 768px) {
    & {
      flex-basis: 100%;
      max-width: 100%;
      margin-top: 40px;
    }
  }
}

form {
  flex-direction: column;
  gap: 1rem;
  max-width: 50%;
  display: flex;

  @media (width <= 768px) {
    & {
      max-width: 100%;
    }
  }
}

label {
  align-items: center;
  gap: 1rem;
  display: flex;
}

input, textarea {
  background-color: var(--bg-color);
  width: 100%;
  color: var(--text-color);
  border: 1px solid #418feb;
  border-radius: 5px;
  padding: 10px;
  display: block;
}

footer {
  background-color: var(--bg-color);
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  padding: 1rem;
  display: flex;
  bottom: 0;
}

.pagination {
  justify-content: center;
  padding-top: 20px;
  display: flex;
}
/*# sourceMappingURL=index.79f1de2b.css.map */
