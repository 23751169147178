nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: var(--bg-color);
  position: sticky;
  top: 0;

  .nav {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 4;

    * {
      margin-right: 1rem;
    }
  }

  .logout {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
  }
}

.hamburger-menu {
  background-color: transparent;
  border: none;
  fill: #fff;
}

.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: var(--bg-color);
  border: 1px solid #418feb;
  border-radius: 4px;
  margin: 0;
  padding: 10px;
  list-style: none;
  z-index: 1000;
  min-width: auto;
  overflow: hidden;
}

.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown-menu li {
  padding: 12px 20px;
  white-space: nowrap;
}

.carriage-icon {
  width: 50px;
  height: 50px;
  display: inline-block;
  margin: 10px;
  transition: transform 0.3s ease, filter 0.3s ease;

  &:hover {
    transform: scale(1.1);
    filter: brightness(1.2);
  }
}
