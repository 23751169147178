.breadcrumbs-container {
  display: flex; /* Ensure breadcrumbs stay together */
  align-items: center; /* Align items vertically in the center */
  padding: 1rem 2rem;
}

.breadcrumbs {
  display: flex; /* Keep breadcrumb links and spans on the same line */
  gap: 8px; /* Add space between breadcrumb items */
}

.breadcrumbs a {
  text-decoration: none;
  color: var(--breadcrumb-color); /* Breadcrumb link styling */
  font-weight: bold; /* Bold text */

  &:hover {
    text-decoration: underline; /* Underline on hover */
  }
}

.breadcrumbs span {
  color: gray; /* Non-clickable breadcrumb styling */
}
