.login-error {
  color: #fff;
  padding: 20px;
  margin: 20px 0;
  background-color: #a92d42;
  border: #ff7686 1px solid;
  border-radius: 6px;
  transition: opacity 0.3s ease-in-out;
  max-width: 50%;

  @media (max-width: 768px) {
    max-width: 100%;
  }
}
