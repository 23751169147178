@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

:root {
  --bg-color: #121212;
  --text-color: #ffffff;
  --breadcrumb-color: #499bff;
  --pagination-bgcolor: rgba(72 155 255 / 0.2);
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
  transition: background-color 0.3s, color 0.3s;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
}

body.light {
  --bg-color: #ffffff;
  --text-color: #000000;
}

.layout {
  padding: 1rem 2rem;
}

h1,
h2,
h3 {
  margin: 0;
}

button {
  cursor: pointer;
  padding: 10px 15px;
  font-size: 0.9em;
  border-radius: 5px;
}

a {
  text-decoration: none;
  color: #499bff;

  &:hover {
    text-decoration: underline;
  }
}

svg {
  stroke: var(--text-color);
}

.btn-primary {
  color: #fff;
  background-color: #499bff;
  border: none;

  &:hover {
    background-color: #418feb;
  }
}

.btn-secondary {
  background-color: transparent;
  color: #499bff;
  border: #418feb 1px solid;

  &:hover {
    background-color: #418feb;
    color: #fff;
  }
}

.btn-dropdown {
  background-color: transparent;
  color: #499bff;
  border: none;

  &:hover {
    text-decoration: underline;
  }
}

.camera-display {
  display: flex;

  @media (max-width: 768px) {
    flex-flow: row wrap;
  }
}

.camera {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  max-width: 50%;
  padding-top: 30px;
  margin-right: 20px;

  .camera-info {
    display: flex;
    padding-bottom: 20px;
    align-items: baseline;

    * {
      margin-right: 1rem;
    }
  }

  img {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }

  @media (max-width: 768px) {
    flex-basis: 100%;
    max-width: 100%;
    margin-top: 40px;
  }
}

form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 50%;

  @media (max-width: 768px) {
    max-width: 100%;
  }
}

label {
  display: flex;
  align-items: center;
  gap: 1rem;
}

input,
textarea {
  width: 100%;
  display: block;
  border: #418feb 1px solid;
  border-radius: 5px;
  padding: 10px;
  background-color: var(--bg-color);
  color: var(--text-color);
}

footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  bottom: 0;
  margin-bottom: 0;
  background-color: var(--bg-color);
}

.pagination {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}
